import "../css/Home.css"; // 引入样式文件

import React, { useEffect, useRef, useState } from "react";

import { Button } from "antd";
import { Invitation } from "../component/Invitation";
import { MusicIcon } from "../component/MusicIcon";
import RotateMusic from "../component/RotateMusic";

const Home = () => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(true);
    const audioRef = useRef(null); // 用于存储音频实例

    const music = (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
            <path d="M 11.396484 4.1113281 C 9.1042001 4.2020187 7 6.0721788 7 8.5917969 L 7 39.408203 C 7 42.767694 10.742758 44.971891 13.681641 43.34375 L 41.490234 27.935547 C 44.513674 26.260259 44.513674 21.739741 41.490234 20.064453 L 13.681641 4.65625 C 12.94692 4.2492148 12.160579 4.0810979 11.396484 4.1113281 z M 11.431641 7.0664062 C 11.690234 7.0652962 11.961284 7.1323321 12.226562 7.2792969 L 40.037109 22.6875 C 41.13567 23.296212 41.13567 24.703788 40.037109 25.3125 L 12.226562 40.720703 C 11.165446 41.308562 10 40.620712 10 39.408203 L 10 8.5917969 C 10 7.9855423 10.290709 7.5116121 10.714844 7.2617188 C 10.926911 7.136772 11.173048 7.0675163 11.431641 7.0664062 z"></path>
        </svg>
    );

    // 播放音乐
    const handlePlay = () => {
        if (!audioRef.current) {
            audioRef.current = new Audio("/music/loveyou.mp3");
            audioRef.current.loop = true; // 设置循环播放
        }
        if (audioRef.current.paused) {
            audioRef.current.play().catch((error) => {
                console.error("播放失败:", error);
            });
        }
    };

    // 点击遮罩层处理
    const handleClick = () => {
        setInitialMusicRotate(true);
        setIsOverlayVisible(false);
        handlePlay();
        setDataReady(true);
    };

    // 组件卸载时暂停音乐
    useEffect(() => {
        return () => {
            if (audioRef.current && !audioRef.current.paused) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, []);

    const handleMusicIconClick = (isRotating) => {
        if (!audioRef.current) {
            return;
        }
        if (isRotating && audioRef.current.paused) {
            audioRef.current.play().catch((error) => {
                console.error("播放失败:", error);
            });
        } else if (!isRotating && !audioRef.current.paused) {
            audioRef.current.pause();
        }
    };

    const [initialMusicRotate, setInitialMusicRotate] = useState(false);
    const [dataReady, setDataReady] = useState(false);

    return (
        <>
            {isOverlayVisible && (
                <div className="overlay" onClick={handleClick}>
                    <Button className="play-button" aria-label="播放音乐">
                        <MusicIcon />
                    </Button>
                </div>
            )}
            {dataReady && (
                <RotateMusic onPlayPause={handleMusicIconClick} initialRotate={initialMusicRotate}></RotateMusic>
            )}

            <Invitation></Invitation>
            <a
                className="icpRef"
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    textAlign: "center",
                    pointerEvents: "auto",
                    color: "rgb(200,200,200)",
                    display: "block",
                    textDecoration: "none",
                }}
            >
                沪ICP备2022030825号-2
            </a>
        </>
    );
};

export default Home;
