import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

export const Barrage = ({ datas }) => {
    const [messages, setMessages] = useState([]);
    const intervalRef = useRef(null);
    const dataIndexRef = useRef(0);

    // 可见性处理
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                // 页面不可见时清除所有状态
                clearInterval(intervalRef.current);
                setMessages([]);
                dataIndexRef.current = 0;
            } else {
                // 页面可见时重新初始化
                startInterval();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
    }, []);

    // 定时器启动
    const startInterval = () => {
        intervalRef.current = setInterval(() => {
            setMessages((prev) => {
                const newMsg = {
                    ...datas[dataIndexRef.current % datas.length],
                    id: Date.now() + Math.random(),
                };
                dataIndexRef.current++;
                return [...prev, newMsg];
            });
        }, 500);
    };

    // 初始启动
    useEffect(() => {
        startInterval();
        return () => clearInterval(intervalRef.current);
    }, []);

    return (
        <div
            style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                height: "100vh",
                overflow: "hidden",
                zIndex: 50,
                pointerEvents: "none",
            }}
        >
            <AnimatePresence>
                {messages.map((msg) => (
                    <motion.div
                        key={msg.id}
                        initial={{ opacity: 1, y: "100vh" }}
                        animate={{
                            y: "60vh",
                            transition: {
                                duration: 4,
                                ease: "linear",
                            },
                        }}
                        exit={{ opacity: 0 }}
                        style={{
                            position: "absolute",
                            left: "5%",
                            color: "gray",
                            fontSize: "1.1rem",
                            whiteSpace: "nowrap",
                            padding: "6px 12px",
                            backgroundColor: "transparent",
                        }}
                        onAnimationComplete={() => {
                            setMessages((prev) => prev.filter((m) => m.id !== msg.id));
                        }}
                    >
                        <span>{msg.name}</span>：{msg.content}
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
};
