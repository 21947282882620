import {
    Button,
    Checkbox,
    Form,
    Input,
    InputNumber,
    Modal,
    Pagination,
    Popconfirm,
    Select,
    Space,
    Switch,
    Table,
    Tabs,
    Tag,
    message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import axiosInstance from "../config/axiosInstance";

const ValidationModal = ({ open, onOk, onClose }) => {
    const handleOk = async () => {
        if (!validated) {
            message.info("请先完成验证码认证");
        } else {
            onOk();
        }
    };

    const handleClose = () => {
        onClose();
    };

    const [validated, setValidated] = useState(false);

    const handleCaptchaError = () => {
        message.error("验证系统出错，请稍后再试");
    };

    const handleCaptchaVerify = () => {
        setValidated(true);
        message.success("验证成功");
    };

    return (
        <>
            <Modal
                title="请填写验证码"
                open={open}
                onOk={handleOk}
                onCancel={handleClose}
                style={{ top: "20%", textAlign: "center" }}
                bodyStyle={{ height: "20vh", overflow: "auto" }} // 这里设置高度
                okText={`提交`}
            >
                <div style={{ marginTop: "5vh" }}>
                    <HCaptcha
                        sitekey="664eabde-7f2c-49fe-8160-f6ca26d2dc1e"
                        onError={handleCaptchaError}
                        onVerify={handleCaptchaVerify}
                        languageOverride="zh-CN"
                    />
                </div>
            </Modal>
        </>
    );
};

export default ValidationModal;
