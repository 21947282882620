import { Button, Input, message } from "antd";
import React, { useEffect, useRef, useState } from "react";

import AMapComponent from "./AMap";
import { Barrage } from "./Barrage";
import ValidationModal from "./Validation";
import messsageService from "../services/messsageService";
import { v4 as uuidv4 } from "uuid";

export const Invitation = ({}) => {
    useEffect(() => {
        const fetch = async () => {
            try {
                await messsageService.getToken();
                const data = await messsageService.getAllMessages();
                setMessageDatas(data);
                if (data && data.length > 0) {
                    setDataReady(true);
                }
            } catch (error) {
                message.error(error.message);
            }
        };
        fetch();
    }, []);

    const [messageDatas, setMessageDatas] = useState([]);
    const [dataReady, setDataReady] = useState(false);

    const [inputVal, setInputVal] = useState();
    const handleInputChange = (val) => {
        setInputVal(val);
    };

    const checkData = () => {
        if (!inputVal || inputVal === ``) {
            message.info("请填写您的祝福!谢谢！");
            return false;
        }
        if (!signed || signed.length === 0) {
            message.info("请完成签名!谢谢！");
            return false;
        }
        if (!containsChineseOrEnglish(signed)) {
            message.info("签名必须包含中文或英文!谢谢！");
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        const valid = checkData();
        if (!valid) {
            return;
        }
        setIsValidationModalVisible(true);
    };

    const [isValidationModalVisible, setIsValidationModalVisible] = useState(false);

    const handleValidationModalOK = async () => {
        const msgData = {
            id: uuidv4(),
            name: signed,
            content: inputVal,
        };
        await messsageService.addNewMessage(msgData);
        message.success(`谢谢您的祝福！`);
        setInputVal();
        setIsValidationModalVisible(false);
    };

    const handleValidationModalClose = () => {
        setIsValidationModalVisible(false);
    };

    const [signed, setSigned] = useState();
    const handleSignedInputChanged = (val) => {
        setSigned(val?.trim());
    };

    function containsChineseOrEnglish(str) {
        return /[\u4e00-\u9fa5]|[a-zA-Z]/.test(str);
    }
    return (
        <>
            {/* <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "16pt", fontWeight: "bold", fontFamily: "serif", color: "black" }}>
                    余忠晟&杨奕芸婚礼邀请函
                </p>
                <p>2025年3月</p>
               
            </div> */}
            <div style={{ display: "flex", justifyContent: "center", width: "80%", marginTop: "2vh" }}>
                <img src="/img/s1.png" style={{ width: "100%", height: "auto" }} />
            </div>
            <hr style={{ width: "90%", margin: "0 auto", marginTop: "3vh", border: "1px solid rgb(233,233,233)" }}></hr>
            <div style={{ display: "flex", marginLeft: "60%", width: "40%", marginTop: "3vh" }}>
                <img src="/img/s2.png" style={{ width: "100%", height: "auto" }} />
            </div>

            <div style={{ marginTop: "4vh" }}>
                <img src="/img/u0.JPG" style={{ width: "100%", height: "auto" }} />
            </div>

            <div style={{ display: "flex", justifyContent: "center", marginTop: "2vh" }}>
                <img src="/img/s5.png" style={{ width: "50%", height: "auto" }} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/img/s3.png" style={{ width: "60%", height: "auto" }} />
            </div>

            <div style={{ marginTop: "3vh", textAlign: "center" }}>
                <p>壬寅年的春分</p>
                <p>爱国路上的玉兰花枝</p>
                <p>缀满了朝露</p>
            </div>
            <div style={{ display: "flex", width: "100%", alignItems: "stretch" }}>
                <div style={{ width: "40%", textAlign: "right", alignSelf: "center" }}>
                    <p>十二号线的车厢摇晃着</p>
                    <p>岳麓山间的晨曙</p>
                    <p>在睫毛间</p>
                    <p>汇成了柔光</p>
                    <p>湄江河畔的雨雾</p>
                    <p>在心坎里</p>
                    <p>漾作碧波珠</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}> </div>
                <div style={{ width: "50%", display: "flex", justifyContent: "right" }}>
                    <img src="/img/u2.JPG" style={{ width: "100%", height: "auto" }} />
                </div>
            </div>
            <div style={{ marginTop: "3vh", textAlign: "center" }}>
                <p>当报站声淹没咚咚的心跳</p>
                <p>少男和少女</p>
                <p>拾起线谱</p>
                <p>记下萦梁的音符</p>
            </div>
            <div style={{ width: "70%", display: "flex", marginTop: "3vh", justifyContent: "left" }}>
                <img src="/img/u1.jpg" style={{ width: "100%", height: "auto" }} />
            </div>
            <div style={{ width: "70%", display: "flex", marginTop: "-4vh", marginLeft: "30%" }}>
                <img src="/img/u4.JPG" style={{ width: "100%", height: "auto" }} />
            </div>

            <div style={{ marginTop: "5vh", textAlign: "center" }}>
                <p>你行李箱的轮毂</p>
                <p>沾着雨夜的泥和沙</p>
                <p>我的素描本</p>
                <p>画上了浸润的抹布</p>
                <p>自此</p>
                <p>你采撷的积雨云</p>
                <p>总在子夜凝成星露</p>
                <p>融入我心堂宁静的湖</p>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%" }}>
                    <img src="/img/u5.JPG" style={{ width: "100%", height: "auto" }} />
                </div>
                <div style={{ width: "50%" }}>
                    <img src="/img/u6.JPG" style={{ width: "100%", height: "auto" }} />
                </div>
            </div>
            <div style={{ marginTop: "3vh", textAlign: "center" }}>
                <p>煮一杯热茶</p>
                <p>翠芽的芳缕沁溢全屋</p>
                <p>我时常用代码复现着</p>
                <p>你的书页飞出的萤弧</p>
            </div>
            <div
                style={{ width: "10%", display: "flex", justifyContent: "center", margin: "0 auto", marginTop: "3vh" }}
            >
                <img src="/img/s4.png" style={{ width: "100%", height: "auto" }} />
            </div>
            <div
                style={{ width: "100%", display: "flex", justifyContent: "center", margin: "0 auto", marginTop: "3vh" }}
            >
                <img src="/img/u7.JPG" style={{ width: "100%", height: "auto" }} />
            </div>
            <div style={{ marginTop: "3vh", textAlign: "center" }}>
                <p>每当楼群的白炽灯漂成渔火</p>
                <p>我便旋开灶台</p>
                <p>让油盐轨迹</p>
                <p>蜿蜒展舒</p>
                <p>你用番茄红</p>
                <p>点缀我</p>
                <p>因护你而见耸立的</p>
                <p>臂膀山矗</p>
            </div>
            <div style={{ display: "flex", width: "100%", alignItems: "stretch", marginTop: "3vh" }}>
                <div style={{ width: "60%", display: "flex", justifyContent: "right" }}>
                    <img src="/img/u8.JPG" style={{ width: "100%", height: "auto" }} />
                </div>
                <div style={{ width: "5%", textAlign: "right" }}> </div>
                <div style={{ width: "35%", textAlign: "left", alignSelf: "center" }}>
                    <p>窗外的南北高架</p>
                    <p>随流辉的汽车延伸着</p>
                    <p>像韧蔓的根系</p>
                    <p>朝着西南的方向</p>
                    <p>生长出炽烈的誓书</p>
                </div>
            </div>
            <div style={{ display: "flex", width: "100%", alignItems: "stretch", marginTop: "3vh" }}>
                <div style={{ width: "45%", textAlign: "right", alignSelf: "center" }}>
                    <p>约定要做江南的连理木</p>
                    <p>分担寒潮与风雾</p>
                    <p>共享流岚和虹霓</p>
                    <p>等到未来</p>
                    <p>等到年轮裹住所有经纬度</p>
                    <p>一起品茗赤水河畔</p>
                    <p>将时间酿成的酒</p>
                </div>
                <div style={{ width: "5%", textAlign: "right" }}> </div>
                <div style={{ width: "50%", display: "flex", justifyContent: "right" }}>
                    <img src="/img/u9.JPG" style={{ width: "100%", height: "auto" }} />
                </div>
            </div>
            <div style={{ marginTop: "3vh", textAlign: "center" }}>
                <p>而现在</p>
                <p>悬铃木高举琉璃杯盏</p>
                <p>年轮旋开了绯红绸幕</p>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "0 auto" }}>
                <img src="/img/u10.JPG" style={{ width: "100%", height: "auto" }} />
            </div>
            <div
                style={{ width: "10%", display: "flex", justifyContent: "center", margin: "0 auto", marginTop: "4vh" }}
            >
                <img src="/img/s4.png" style={{ width: "100%", height: "auto" }} />
            </div>
            <div style={{ marginTop: "3vh", textAlign: "center" }}>
                <p>请收下这枚婚礼邀约</p>
                <p>让汨罗江水泽过翠黔山麓</p>
                <p>待槐月良辰</p>
                <p>我们相约在北纬27度</p>
            </div>
            <div style={{ height: "10vh" }}> </div>
            <AMapComponent></AMapComponent>

            <div style={{ marginTop: "3vh", textAlign: "center" }}>
                <p style={{ color: "black" }}>2025年5月1日11:08 婚礼</p>
                <p style={{ color: "black" }}>2025年4月30日-5月1日 宴会</p>
                <p style={{ color: "black" }}>贵州省湄潭县圣地酒店</p>
                <p style={{ color: "black" }}>邀请人：余忠晟&杨奕芸</p>
            </div>
            <div
                style={{ width: "60%", display: "flex", justifyContent: "center", margin: "0 auto", marginTop: "3ch" }}
            >
                <img src="/img/s6.png" style={{ width: "100%", height: "auto" }} />
            </div>
            <div style={{ height: "5vh" }}> </div>
            <Input.TextArea
                style={{
                    height: "20vh", // 设置高度
                    textAlign: "left", // 左对齐
                    overflowY: "auto", // 垂直滚动
                    resize: "none", // 禁止用户调整大小
                }}
                rows={4} // 默认显示的行数
                value={inputVal}
                onChange={(e) => handleInputChange(e.target.value)}
            />
            <div style={{ display: "flex", width: "100%", marginTop: "1vh" }}>
                <span>请签名</span>
                <Input
                    style={{ width: "40%", marginLeft: "5%" }}
                    value={signed}
                    onChange={(e) => handleSignedInputChanged(e.target.value)}
                    maxLength={10}
                ></Input>
            </div>
            <div style={{ position: "relative", width: "100%" }}>
                <Button
                    style={{
                        position: "absolute", // 绝对定位
                        right: "1%", // 靠右边缘 10%
                        marginTop: "1vh", // 顶部间距
                        display: "flex", // 如果需要 flex 布局
                    }}
                    onClick={handleSubmit}
                >
                    祝福
                </Button>
            </div>
            <div style={{ height: "10vh" }}> </div>

            {dataReady && <Barrage datas={messageDatas}></Barrage>}
            <ValidationModal
                open={isValidationModalVisible}
                onOk={handleValidationModalOK}
                onClose={handleValidationModalClose}
            ></ValidationModal>
        </>
    );
};
