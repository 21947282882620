import axios from "axios";

const axiosInstance = axios.create({
    //baseURL: `http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/api`, // 替换为你的 API 基础 URL
    baseURL: `https://www.dafool.cn/api`, // 替换为你的 API 基础 URL
    timeout: 20000, // 请求超时时间（毫秒）
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true, // 确保发送 Cookie
});

export default axiosInstance;
