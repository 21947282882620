import axiosInstance from "../config/axiosInstance";
import { message } from "antd";

class MessageService {
    async getToken() {
        try {
            await axiosInstance.post("/getToken");
        } catch (error) {
            message.error("无法使用留言功能，请刷新网页重试!");
            return null;
        }
    }

    async getAllMessages() {
        try {
            const response = await axiosInstance.get("/messages");
            return response.data;
        } catch (error) {
            message.error("无法使用留言功能，请刷新网页重试!");
            return null;
        }
    }

    // 添加新消息
    async addNewMessage(newMessageData) {
        try {
            const response = await axiosInstance.post("/messages", newMessageData);
            return response.data;
        } catch (error) {
            message.error("添加留言失败!");
            return null;
        }
    }
}

export default new MessageService();
