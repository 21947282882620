import { Button } from "antd";
import React, { useEffect, useState } from "react";

const AMapComponent = () => {
    const initialLng = 107.43746;
    const initialLat = 27.738986;
    const initialZoom = 15;

    const [map, setMap] = useState(null);

    useEffect(() => {
        // 异步加载高德地图脚本
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://webapi.amap.com/maps?v=1.4.8&key=7cc472d8b1b544ac14df01ad51996a17`;
        script.onload = () => {
            // 初始化地图
            const mapInstance = new window.AMap.Map("container", {
                zoom: initialZoom, // 设置地图显示的缩放级别
                center: [initialLng, initialLat], // 设置地图中心点坐标
            });

            // 创建一个 Marker 实例：（标记点）
            const marker = new window.AMap.Marker({
                position: new window.AMap.LngLat(initialLng, initialLat), // 经纬度对象
                title: "湄潭县圣地酒店",
            });

            // 将创建的点标记添加到已有的地图实例
            mapInstance.add(marker);

            // 保存地图实例到状态
            setMap(mapInstance);
        };
        document.body.appendChild(script);
    }, []); // 空数组确保只在组件挂载时加载一次

    // 放大地图
    const handleZoomIn = () => {
        if (map) {
            map.zoomIn();
        }
    };

    // 缩小地图
    const handleZoomOut = () => {
        if (map) {
            map.zoomOut();
        }
    };

    // 重置地图
    const handleReset = () => {
        if (map) {
            map.setZoomAndCenter(initialZoom, [initialLng, initialLat]);
        }
    };

    return (
        <div style={{ display: "flex", width: "80%", margin: "0 auto" }}>
            <div id="container" style={{ width: "90%", height: "20vh" }}></div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "10px",
                    width: "5%",
                }}
            >
                <Button
                    onClick={handleZoomIn}
                    style={{
                        marginBottom: "10px",
                        width: "100%", // 宽度为父容器的 100%
                        minWidth: "30px", // 最小宽度
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px", // 添加内边距
                    }}
                >
                    +
                </Button>
                <Button
                    onClick={handleZoomOut}
                    style={{
                        marginBottom: "10px",
                        width: "100%", // 宽度为父容器的 100%
                        minWidth: "30px", // 最小宽度
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px", // 添加内边距
                    }}
                >
                    -
                </Button>
                <Button
                    onClick={handleReset}
                    style={{
                        background: "none",
                        width: "100%", // 宽度为父容器的 100%
                        minWidth: "30px", // 最小宽度
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px", // 添加内边距
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="rgb(177,177,177)"
                        width="24px"
                        height="24px"
                        viewBox="0 0 1920 1920"
                    >
                        <path
                            d="M960 0v213.333c411.627 0 746.667 334.934 746.667 746.667S1371.627 1706.667 960 1706.667 213.333 1371.733 213.333 960c0-197.013 78.4-382.507 213.334-520.747v254.08H640V106.667H53.333V320h191.04C88.64 494.08 0 720.96 0 960c0 529.28 430.613 960 960 960s960-430.72 960-960S1489.387 0 960 0"
                            fill-rule="evenodd"
                        />
                    </svg>
                </Button>
            </div>
        </div>
    );
};

export default AMapComponent;
