import "./App.css";
import Home from "./pages/Home";

function App() {
    return (
        <div className="App">
            <div className="container">
                <Home></Home>
            </div>
        </div>
    );
}

export default App;
