import { useState, useRef, useEffect } from "react";
import { RotateMusicSVG } from "./MusicIcon";

const RotateMusic = ({ onPlayPause, initialRotate }) => {
    const [isRotating, setIsRotating] = useState(initialRotate);
    const svgRef = useRef(null);
    const rotationRef = useRef(0); // 记录旋转角度
    let animationFrame;

    useEffect(() => {
        const rotate = () => {
            if (svgRef.current) {
                rotationRef.current += 2; // 每帧旋转 2 度
                svgRef.current.style.transform = `rotate(${rotationRef.current}deg)`;
                animationFrame = requestAnimationFrame(rotate);
            }
        };

        if (isRotating) {
            animationFrame = requestAnimationFrame(rotate);
        } else {
            cancelAnimationFrame(animationFrame);
        }

        return () => cancelAnimationFrame(animationFrame);
    }, [isRotating]);

    const handleClick = () => {
        setIsRotating(!isRotating);
        onPlayPause(!isRotating);
    };

    return (
        <div
            style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                cursor: "pointer",
                outline: "none", // 取消默认点击高亮
                userSelect: "none", // 禁止文本选择
                WebkitTapHighlightColor: "transparent", // 移动端去除点击高亮
                scale: "0.5",
                opacity: "0.5",
            }}
            onClick={handleClick}
        >
            <RotateMusicSVG ref={svgRef} />
        </div>
    );
};

export default RotateMusic;
